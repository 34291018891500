<template>
	<div class="generator">
		<div class="col" ref="signature-default">
			<signature-default v-bind="sigdata"/>
			<buttons reference="signature-default" @copy="showCode" @clipboard="copyCode" @export="downloadSingle" :enabled="isValid && isValidPhoto"/>
		</div>
		<div class="col" ref="signature-nophoto">
			<signature-nophoto v-bind="sigdata"/>
			<buttons reference="signature-nophoto" @copy="showCode" @clipboard="copyCode" @export="downloadSingle" :enabled="isValid"/>
		</div>
		<div class="col" ref="signature-noreply">
			<signature-reply v-bind="sigdata"/>
			<buttons reference="signature-noreply" @copy="showCode" @clipboard="copyCode" @export="downloadSingle" :enabled="isValid" />
		</div>
		<fields @update-signatures="update"/>
		<div class="actions">
			<details class="instructions">
				<summary><b>Instrukcja</b></summary>
				<ol>
					<li>wypełnij stopki uzupełnijąc pola po lewej stronie</li>
					<li>W przypadku pierwszej stopki należy również uploadować zdjęcie</li>
					<li>Wybierz jedną z trzech operacji dostępnych dla wybranej stopki lub&hellip;</li>
					<li>&hellip;pobierz uzupełnione stopki w formie archwum <b>.zip</b> lub&hellip;</li>
					<li>&hellip;wyślij w/w archiwum na podany adres e-mail z opcjonalną wiadomością, korzystając z formularza po prawej stronie</li>
					<li>W przypadku braku zdjęcia pierwsza stopka pozostanie zablokowana i nie trafi do archiwum.</li>
				</ol>
			</details>
			<div v-show="sent.status" class="status" :class="sent.status">
				{{ sent.msg }}
			</div>
			<div class="download">
				<button type="button" @click="downloadZip" :disabled="!isValid">Pobierz Paczkę <b>.zip</b> z plikami stopek</button><span>lub&hellip;</span>
			</div>
		</div>
		<mailer @send="send" :enabled="isValid" />
		<code class="html">
			{{ html }}
		</code>
		<p class="notice">
			<b>DISCLAIMER:</b> Work in progress. Obecny wygląd może nie odzwieciedlać ostatecznego wyglądu i działania narzędzia :)
		</p>
	</div>
	
</template>

<style scoped>
	:root {
		--red: 255,0,0;
		--green: 0,255,0;
		--yellow: 255,255,0;
	}
	*,*::before,*::after { 
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}
	.generator {
		font-family: arial, sans-serif;
		padding: 20px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-auto-rows:auto;
		gap: 20px;
	}
	.col{
		border: 1px solid #ccc;
		padding: 20px;
		display: grid;
		grid-template-rows: 1fr auto;
	}
	.actions {
		display: flex;
		flex-direction: column;
	}
	.actions .instructions {
		line-height: 1.2;
		margin-bottom: 10px;
	}
	.actions .instructions summary {
		cursor: pointer;
	}
	.actions .instructions ol{
		font-size: 0.8em;
		margin-left: 15px;
	}
	.actions .download {
		margin-top: auto;
		text-align: right;
		flex: 0 1 auto;
		order: 2
	}
	.actions button {
		display: inline-block;
		padding: 5px;
		margin-right:20px;
	}
	.actions .status {
		flex: 0 1 auto;
		font-family: arial,sans-serif;
		border: 1px solid rgba(var(--yellow), 1);
		background: rgba(var(--yellow), 0.5);
		padding: 5px;
		margin-bottom: 20px;
	}
	.actions .status.ok {
		border-color: rgba(var(--green), 1);
		background: rgba(var(--green), 0.5);
	}
	.actions .status.error {
		border-color: rgba(var(--red), 1);
		background: rgba(var(--red), 0.5);
	}
	.html {
		display:block;
		padding: 20px;
		background: #ccc;
		max-width: 100%;
		overflow: auto;
		grid-column: span 3;
	}
	.notice {
		font-family: arial, sans-serif;
		grid-column: span 3;
		line-height: 1.5
	}
</style>

<script>
	import SignatureDefault from './SignatureDefault.vue'
	import SignatureNophoto from './SignatureNophoto.vue'
	import SignatureReply from './SignatureReply.vue'
	import Fields from './Fields.vue'
	import Buttons from './Buttons.vue'
	import Mailer from './Mailer.vue'
	import JSZip from 'jszip'
	import mailer from './mailer.php'
	import axios from 'axios'

	let placeholderPhoto = 'https://via.placeholder.com/85/CCCCCC/000000'

	export default {
		data() {
			return {
				sigdata: {
					name: '',
					position: '',
					position2: '',
					phone: '',
					mail: '',
					photo: placeholderPhoto
				},
				isValid: false,
				isValidPhoto: false,
				html: 'Skopiuj stąd html wybranej stopki',
				sent: {
					status: '',
					msg: ''
				}
			}
		},
		components: {
			SignatureDefault, SignatureNophoto, SignatureReply, Fields, Buttons, Mailer
		},
		methods: {
			update(data) {
				this.sigdata = data.fields
				this.isValid = data.validFields
				this.isValidPhoto = data.validPhoto
			},
			getHTML(ref) {
				return this.$refs[ref].firstChild.innerHTML;
			},
			showCode(ref) {
				this.html = this.getHTML(ref)
			},
			copyCode(ref) {
				navigator.clipboard.writeText( this.getHTML(ref))
					.then( () => { 
						this.sent = {status: 'ok', msg: `Stopka: ${ref} skopiowana do schowka` } 
					} )
					.catch( () => {
						this.sent = {status: 'error', msg: `Wystąpił błąd podczas kopiowania stopki do schowka` } 
					} )  
			},
			createFile(ref) {
				return new File( [ this.getHTML(ref) ], `${ref}.htm`, { type: 'text/html' } )
			},
			download( file, filename) {
				let url = URL.createObjectURL( file )
				let anchor = Object.assign(document.createElement('a'), {
					href: url,
					download: filename
				} )
				anchor.click()
				URL.revokeObjectURL(url);
			},
			downloadSingle(ref) {
				let file = this.createFile(ref)
				this.download(file, file.name )
			},
			async createZip() {
				let jszip = new JSZip();
				let signatures = jszip.folder('signatures')
				for ( let ref in this.$refs) {
					if (ref === 'signature-default' && !this.isValidPhoto) continue
					let file = this.createFile(ref)
					signatures.file( file.name, file )
				}
				let zip = await signatures.generateAsync({type: 'blob'})
				return zip
			},
			async downloadZip() {
				let zip = await this.createZip()
				this.download( zip, 'signatures.zip' )
			},
			async send(formdata) {
				this.sent.status = 'notice'
				this.sent.msg = 'Wysyłanie...'
				let zip = await this.createZip()
				await formdata.append('attachment', zip ) 
				let options = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				let response = await axios.post( mailer, formdata, options)
				this.sent.status = response.data.status
				this.sent.msg = response.data.msg
			}
		}
	}
</script>