<template>
	<form @submit.prevent="send" class="mailer">
		<textarea name="msg" rows="5" :placeholder="message"></textarea>
		<label><span>Email odbiorcy:</span><input type="email" name="mail" required/></label>
		<button type="submit" :disabled="!enabled">Wyślij paczkę <b>.zip</b> e-mailem</button>
	</form>
</template>

<style scoped>
	form {
		height: 100%;
		display: grid;
		grid-template-rows: 1fr auto;
		grid-template-columns: 1fr auto;
		gap: 10px 20px;
	}

	textarea {
		grid-column: span 2;
		padding: 5px;
		border: 1px solid #ccc
	}
	label {
		font-family: arial, sans-serif;
		display: flex;
		gap: 20px;
		align-items: center;
	}
	label:first-child {
		grid-column: span 2;
	}
	
	label span {
		flex: 0 1 auto;
		text-align: right;
	}
	label input {
		flex: 1 0 auto;
		padding: 5px;
		border: 1px solid #ccc
	}
	button {
		display:inline-block;
		width: 100%;
		padding: 5px;
	}
</style>

<script>
export default {
	props: ['enabled'],
	data() {
		return {
			message: "Wyślij paczkę .zip z sygnaturkami na adres e-mail.\nOpcjonalna wiadomość dla odbiorcy..."
		}
	},
	methods: {
		send(e) {
			let data = new FormData( e.target )
			this.$emit('send', data )
		}
	}
}
</script>

