<template>
	<div class="buttons">
		<button type="button" @click="$emit('copy',reference)" :disabled="!enabled">
			Wyświetl kod HTML
		</button>
		<button type="button" @click="$emit('clipboard',reference)" :disabled="!enabled">
			Kopiuj do schowka
		</button>
		<button type="button"  @click="$emit('export',reference)" :disabled="!enabled">
			Eksportuj jako plik
		</button>
	</div>
</template>

<style scoped>
	.buttons {
		display: grid;
		gap: 20px;
		grid-template-columns: 1fr 1fr 1fr; 
		border-top: 1px solid #ccc;
		padding-top: 20px;
	}
	button {
		padding: 5px;
	}
</style>

<script>
export default {
	props: ['reference', 'enabled']
}
</script>
