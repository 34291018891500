<template>
	<div class="fields">
		<label>
			<span>Imię i Nazwisko:</span>
			<input v-model.trim="fields.name" @input="validate">
		</label>
		<label>
			<span>Stanowisko:</span>
			<input v-model.trim="fields.position" @input="validate">
		</label>
		<label class="position2">
			<span>c.d. (opcjonalnie):</span>
			<input v-model.trim="fields.position2" @input="validate" @change="validateOptional">
		</label>
		<label>
			<span>Telefon:</span>
			<i class="preffix">+48</i><input class="phone" type="tel" v-model.trim="fields.phone" @input="validate">
		</label>
		<label>
			<span>Email:</span>
			<input class="email" type="text" v-model.trim="fields.mail" @input="validate"><i class="suffix">@whitelabelcoders.com</i>
		</label>
		<label>
			<span>Fotka:</span><input type="file" accept="image/*" @change="uploadPhoto">
		</label>
		<label>
			<span>Fotka - okrągła:</span><input type="checkbox" v-model="rounded" @change="setPhoto">
		</label>
	</div>
</template>

<style scoped>
	.fields {
		font-family: arial, sans-serif;
	}
	.fields label {
		display: flex;
		align-items: stretch;
		gap: 20px;
	}
	.fields label + label {
		margin-top: 10px;
	}
	.fields label.position2 {
		margin-top: -1px;
	}
	.fields label.position2 {
		margin-top: -1px;
	}
	.fields span {
		width: 200px;
		text-align: right;
		align-self: center
	}
	.fields label.position2 span{
		font-size: 0.9em;
		color: #b0b0b0;
	}
	.fields input {
		flex: 1 0 auto;
		padding: 5px;
		border: 1px solid #ccc;
		border-radius: 0;
	}
	.fields input[type="file"] {
		padding: 0;
		border: none;
	}
	.fields input[type="checkbox"] {
		flex: 0 1 auto;
		align-self: center;
		appearance: none;
		height: 20px;
		width: 20px;
	}
	.fields input[type="checkbox"]:checked {
		background: url(data-url:../assets/check.jpg?as=png&width=20&height=20) 0 0 / contain no-repeat;
	}
	.fields input.phone {
		margin-left: -20px;
	}
	.fields input.email {
		margin-right: -20px;
	}
	.fields .preffix,
	.fields .suffix {
		display:inline-block;
		background: #ccc;
		padding: 5px;
		font-size: 0.8em;
	}
</style>

<script>
import round from '../assets/round.png'

let placeholderPhoto = 'https://via.placeholder.com/85/CCCCCC/000000'

export default {
	data() {
		return {
			fields: {
				name: '',
				position: '',
				position2: '',
				phone: '',
				mail: '',
				photo: placeholderPhoto,	
			},
			rounded: false,
			origPhoto: placeholderPhoto,
			roundedPhoto: round,
			isValid: false,
			validPhoto: false
		}
	}, 
	methods: {
		uploadPhoto(e) {
			let file = e.target.files[0]
			let filereader = new FileReader()
			filereader.onload = () => {
				this.origPhoto = filereader.result;
				let img = new Image()
				img.src = this.origPhoto;
				img.onload = () => {
					let canv = Object.assign(document.createElement('canvas'), {
						width: 85,
						height: 85
					} )
					let ctx = canv.getContext('2d')
					ctx.beginPath();
					ctx.arc( canv.width/2, canv.height/2, canv.height/2, 0, Math.PI*2)
					ctx.closePath()
					ctx.clip()
					ctx.drawImage( img, 0, 0, 85, 85)
					this.roundedPhoto = canv.toDataURL()
					this.setPhoto()
					this.validate()
				}	
			}
			filereader.readAsDataURL(file);
		},
		setPhoto() {
			this.fields.photo = this.rounded ? this.roundedPhoto : this.origPhoto
		},
		validatePhoto() {
			return this.origPhoto !== placeholderPhoto
		},
		validateFields() {
			return this.fields.name !=='' && this.fields.position !=='' && this.fields.phone !=='' && this.fields.mail !==''
		},
		validate() {
			this.isValid = this.validateFields()
			this.validPhoto = this.validatePhoto()
			this.$emit('updateSignatures', {fields: this.fields, validFields: this.isValid, validPhoto: this.validPhoto } )
		},
		validateOptional() {
			if (this.fields.position2 !== '' && this.fields.position === '') {
				[this.fields.position, this.fields.position2] = [this.fields.position2, '']
			}
			this.validate()
		},
	}


}
</script>